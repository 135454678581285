import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'

function preloadImage(src) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = function() {
            resolve(img)
        }
        img.onerror = img.onabort = function() {
            reject(src)
        }
        img.src = src
    })
}
export const truncate = (string = '', maxLength = 50) => string.length > maxLength
    ? `${string.substring(0, maxLength)}…`
    : string
export const useImagePreloader = (imageList) => {
    const [imagesPreloaded, setImagesPreloaded] = useState(false)

    useEffect(() => {
        let isCancelled = false

        async function effect() {
            // console.log('PRELOAD')
            if (isCancelled) {
                return
            }

            const imagesPromiseList = []
            for (const i of imageList) {
                imagesPromiseList.push(preloadImage(i))
            }
            await Promise.all(imagesPromiseList)

            if (isCancelled) {
                return
            }
            setImagesPreloaded(true)
        }

        effect()

        return () => {
            isCancelled = true
        }
    }, [imageList])

    return { imagesPreloaded }
}
export const calculateOrderSum = (packages) => {
    if (!!packages[0]?.price) {
        return packages
            .filter(pac => pac.name !== 'Discount')
            .reduce((acc, obj) => acc + obj.price, 0)
    }
    return 0
}
export const calculateOrderDiscount = (packages) => {
    const discount = packages.find((line, index) => line.name?.toLowerCase() === 'discount')
    if (discount) {
        return discount.price
    }
    return 0
}
export const calculateOrderTotal = (packages) => {
    const sum = calculateOrderSum(packages)
    const discount = calculateOrderDiscount(packages)
    if (discount) {
        return Math.round(sum * (1 - (discount / 100)))
    }
    return Math.round(sum)
}
export const getOrderRundown = (featureCollection) => {
    let feats = {}

    const totalProducts = featureCollection?.features &&
        featureCollection.features.map((feat) => {
            const [feat_type, feat_name] = feat.properties.drawType.split('_')
            feats[feat_type] = feats[feat_type] || {}
            feats[feat_type][feat_name] = feats[feat_type][feat_name] + 1 || 1
            return feats
        })

    if (totalProducts) {
        return totalProducts[totalProducts.length - 1]
    }

    return {}
}
export const syntaxHighlight = (json) => {
    if (!json) return '' //no JSON from response

    json = json
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function(match) {
            var cls = 'number'
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key'
                } else {
                    cls = 'string'
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean'
            } else if (/null/.test(match)) {
                cls = 'null'
            }
            return '<span class="' + cls + '">' + match + '</span>'
        },
    )
}
export const userHasRole = (user, roleToFind) => user.roles.length && user.roles.filter(role => role.name.split('.')[1] === roleToFind).length > 0
export const timeAgo = (prevDate) => {
    const diff = Number(new Date()) - prevDate
    const minute = 60 * 1000
    const hour = minute * 60
    const day = hour * 24
    const month = day * 30
    const year = day * 365
    switch (true) {
        case diff < minute:
            const seconds = Math.round(diff / 1000)
            return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
        case diff < hour:
            return Math.round(diff / minute) + ' minutes ago'
        case diff < day:
            return Math.round(diff / hour) + ' hours ago'
        case diff < month:
            return Math.round(diff / day) + ' days ago'
        case diff < year:
            return Math.round(diff / month) + ' months ago'
        case diff > year:
            return Math.round(diff / year) + ' years ago'
        default:
            return ''
    }
}
export function decodeHtml(html) {
    var txt = document.createElement('textarea')
    txt.innerHTML = html.replace('\n', '<br />')
    return txt.value
}
export function isMobile() {
    let hasTouchScreen = false
    const UA = navigator.userAgent
    hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    // const r = window.innerWidth <= 800 && window.innerHeight <= 600
    return hasTouchScreen
}
export function launchExternal(url, target = '_blank') {
    window.open(url, target)
}
export function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1)
}
export function short(uuid) {

    if (uuid.indexOf('-') === -1) {
        return uuid
    }
    return uuid.split('-')[4]
}
export function formatDate(input) {
    const date = new Date(input)
    return date.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })
}
export function absoluteUrl(path) {
    return `${process.env.NEXT_PUBLIC_APP_URL}${path}`
}
export function numberFormat(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
export function convertBase64ToFile(base64String, fileName) {
    let arr = base64String.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let uint8Array = new Uint8Array(n)
    while (n--) {
        uint8Array[n] = bstr.charCodeAt(n)
    }
    let file = new File([uint8Array], fileName, { type: mime })
    return file
}
export function saveChart(type, chartRef) {
    const myChart = chartRef.current?.getEchartsInstance()
    if (myChart) {
        const base64String = myChart.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff',
            type: 'png',
        })
        const timecode = dayjs().format('YYYYMMDD')
        const name = 'FaunaPhotonics_' + capitalize(type) + '_' + timecode
        const fileName = name + '.png'
        let file = convertBase64ToFile(base64String, fileName)
        return saveAs(file, fileName)
    }
}
export function getCookie(key) {
    var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')
    return b ? b.pop() : ''
}

export const utils = {
    formatDate,
    absoluteUrl,
    capitalize,
    timeAgo,
}